import { observer } from 'mobx-react'
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SimpleSelect,
  SelectItem,
} from '../../../components/SimpleSelect/SimpleSelect'
import { InputWrap } from '../../../components/AddMerchantForm/AddMerchantForm.styles'
import { StoreContext } from '../../../components/App'
import { TextInput } from '../../../components/TextInput/TextInput'
import { Container, FormSection, ProceedButton } from './enroll.styles'
import { AnimatedBorder } from '../../../components/AnimatedInput/AnimatedBorder'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import {
  GicsSelect,
  GicsSelectValue,
} from '../../../components/GicsSelect/GicsSelect'
import { InputPlaceholder } from '../../../components/AnimatedInput/AnimatedBorder.styles'
import { gicsList } from '../../../constants/gicsFlat'

const mockOptions: SelectItem[] = [
  { value: '1', label: '???' },
  { value: '2', label: '???' },
  { value: '3', label: '???' },
  { value: '4', label: '???' },
]

export const CompanyData = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { companyData: trans },
  } = store.TranslationsState.translations

  const [isPolling, setIsPolling] = useState(false)

  const pollVerificationData = async () => {
    try {
      const res = await axios.get<{
        redirectUrl?: string
      }>(`${process.env.WEB_API_URL}/merchant-enrolment/should-continue`, {
        withCredentials: true,
      })

      if (res.data.redirectUrl) {
        setIsPolling(false)
        window.location.href = res.data.redirectUrl
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(true)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [kvkNumber, setKvkNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [vatNumber, setVatNumber] = useState('')
  const [website, setWebsite] = useState('')
  const [companyLegalForm, setCompanyLegalForm] = useState({
    value: '',
    label: '',
  })
  const [businessSector, setBusinessSector] = useState<GicsSelectValue>([])
  const [activity, setActivity] = useState({ value: '', label: '' })
  const [useCase, setUseCase] = useState({ value: '', label: '' })
  const [salesChannel, setSalesChannel] = useState({ value: '', label: '' })

  const [companyNameFetched, setCompanyNameFetched] = useState(false)
  const [vatNumberFetched, setVatNumberFetched] = useState(false)
  const [websiteFetched, setWebsiteFetched] = useState(false)
  const [activityFetched, setActivityFetched] = useState(false)

  const CompanyLegalFormOptions: SelectItem[] = useMemo(
    () => [
      { value: 'soleProprietorship', label: trans.soleProprietorship },
      { value: 'partnership', label: trans.partnership },
      { value: 'llc', label: trans.llc },
      { value: 'cCorpo', label: trans.cCorpo },
      { value: 'sCorpo', label: trans.sCorpo },
    ],
    [trans]
  )

  const SalesChannelOptions: SelectItem[] = useMemo(
    () => [
      { value: 'ownstores', label: trans.ownstores },
      { value: 'reseller', label: trans.reseller },
      { value: 'thirdparty', label: trans.thirdparty },
    ],
    [trans]
  )

  const UseCaseOptions: SelectItem[] = useMemo(
    () => [
      { value: 'instore', label: trans.instore },
      { value: 'online', label: trans.online },
      { value: 'both', label: trans.both },
      { value: 'other', label: trans.other },
    ],
    [trans]
  )

  const getSphonicData = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/company-lookup`,
        {
          companyNumber: kvkNumber,
          country: 'NL',
        },
        {
          withCredentials: true,
        }
      )

      if (res.data) {
        if (res.data.companyName) {
          setCompanyName(res.data.companyName)
          setCompanyNameFetched(true)
        }
        if (res.data.vatNumber) {
          setVatNumber(res.data.vatNumber)
          setVatNumberFetched(true)
        }
        if (res.data.activity) {
          setActivity(res.data.activity)
          setActivityFetched(true)
        }
        if (res.data.website) {
          setWebsite(res.data.website)
          setWebsiteFetched(true)
        }
      }

      setTimeout(() => {
        setCompanyNameFetched(false)
        setVatNumberFetched(false)
        setActivityFetched(false)
        setWebsiteFetched(false)
      }, 500)

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/data`,
        {
          kvkNumber,
          companyName,
          vatNumber,
          website,
          companyLegalForm,
          businessSector: businessSector.map((sector) => {
            return gicsList.find((item) => item.value === sector)
          }),
          activity,
          useCase,
          salesChannel,
          country: 'NL',
        },
        {
          withCredentials: true,
        }
      )

      setIsPolling(true)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    if (kvkNumber.length === 8 && shouldFetch) {
      getSphonicData()
      setShouldFetch(false)
    }
  }, [kvkNumber])

  useEffect(() => {
    if (
      kvkNumber !== '' &&
      companyName !== '' &&
      vatNumber !== '' &&
      companyLegalForm.value !== '' &&
      businessSector?.at(-1)?.length !== 0 &&
      activity.value !== '' &&
      useCase.value !== '' &&
      salesChannel.value !== ''
    ) {
      setIsProceedDisabled(false)
    } else {
      setIsProceedDisabled(true)
    }
  }, [
    kvkNumber,
    companyName,
    vatNumber,
    companyLegalForm,
    businessSector,
    activity,
    useCase,
    salesChannel,
  ])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(pollVerificationData, 3000) // Poll every 3 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <FormSection>
          <InputWrap>
            <label htmlFor="kvkNumber">{trans.kvknumber}</label>
            <AnimatedBorder isLoading={isDisabled}>
              <TextInput
                type="text"
                value={kvkNumber}
                onChange={(e) => setKvkNumber(e.target.value)}
                name="kvkNumber"
                disabled={isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
        </FormSection>
        <InputWrap>
          <label htmlFor="companyName">{trans.companyName}</label>
          <AnimatedBorder isLoading={isDisabled} isFilled={companyNameFetched}>
            <TextInput
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              name="companyName"
              disabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="companyLegalForm">{trans.companyLegalForm}</label>
          <AnimatedBorder isLoading={isDisabled}>
            <SimpleSelect
              name="companyLegalForm"
              value={companyLegalForm}
              onChange={(val) => setCompanyLegalForm(val as SelectItem)}
              options={CompanyLegalFormOptions}
              isMulti={false}
              isDisabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="vatNumber">{trans.vatNumber}</label>
          <AnimatedBorder isLoading={isDisabled} isFilled={vatNumberFetched}>
            <TextInput
              type="text"
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              name="vatNumber"
              disabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="businessSector">{trans.businessSector}</label>
          <AnimatedBorder isLoading={isDisabled}>
            {isDisabled ? (
              <InputPlaceholder />
            ) : (
              <GicsSelect
                value={businessSector}
                onChange={(val) => setBusinessSector(val as GicsSelectValue)}
              />
            )}
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="activity">{trans.activity}</label>
          <AnimatedBorder isLoading={isDisabled} isFilled={activityFetched}>
            <SimpleSelect
              name="activity"
              value={activity}
              onChange={(val) => setActivity(val as SelectItem)}
              options={mockOptions}
              isMulti={false}
              isDisabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="useCase">{trans.useCase}</label>
          <AnimatedBorder isLoading={isDisabled}>
            <SimpleSelect
              name="useCase"
              value={useCase}
              onChange={(val) => setUseCase(val as SelectItem)}
              options={UseCaseOptions}
              isMulti={false}
              isDisabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="salesChannel">{trans.salesChannel}</label>
          <AnimatedBorder isLoading={isDisabled}>
            <SimpleSelect
              name="salesChannel"
              value={salesChannel}
              onChange={(val) => setSalesChannel(val as SelectItem)}
              options={SalesChannelOptions}
              isMulti={false}
              isDisabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="website">
            {trans.website} ({trans.optional})
          </label>
          <AnimatedBorder isLoading={isDisabled} isFilled={websiteFetched}>
            <TextInput
              type="text"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              name="website"
              disabled={isDisabled}
            />
          </AnimatedBorder>
        </InputWrap>
        <ProceedButton
          disabled={isProceedDisabled}
          onClick={() => handleSubmit()}
        >
          {trans.continue}
        </ProceedButton>
      </form>
    </Container>
  )
})
