import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconPlus from '../../../assets/icon_plus_blue.svg'
import { StoreContext } from '../../../components/App'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { ToRight } from '../EnrollCompany.styles'
import { AddMore, Boxed, Container, ProceedButton } from './enroll.styles'

export interface Actor {
  actorId: string
  info?:
    | {
        firstName: string
        infix: string
        lastName: string
        email: string
        phoneNumber: string
        dateOfBirth: number
      }
    | undefined
  address?:
    | {
        line1: string
        line2: string
        city: string
        postcode: string
        country: string
      }
    | undefined
  providerInfo?: {
    name: string
    dateOfBirth?: number
    address?: string
  }
  isDirectorFromProvider?: boolean
  isUboFromProvider?: boolean
  isApplicant?: boolean
  isDirector?: boolean
  isUbo?: boolean
  isIdentityVerificationStarted?: boolean
  isAddressVerifciaitonStarted?: boolean
  isVerified?: boolean
  isEmailSent?: boolean
}

export const blankActor = {
  actorId: '',
  info: {
    firstName: '',
    infix: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
  },
  address: {
    line1: '',
    line2: '',
    city: '',
    postcode: '',
    country: '',
  },
  providerInfo: {
    name: '',
    dateOfBirth: '',
    address: '',
  },
}

export const Directors = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { directors: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)

  const [people, setPeople] = useState<Actor[]>([])

  const handleAddPerson = () => {
    console.log('add')
  }

  const handleDeletePerson = (id: number) => {
    console.log('delete')
  }

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)
      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        {
          edit: people
            .filter((person) => person.isDirector)
            .map((person) => {
              return {
                ...person,
                info: {
                  ...(person.info ? person.info : blankActor.info),
                  dateOfBirth:
                    person.info?.dateOfBirth ||
                    person.providerInfo?.dateOfBirth ||
                    0,
                },
              }
            }),
        },
        {
          withCredentials: true,
        }
      )

      navigate('/enroll-company/ubos')

      setIsDisabled(false)
    } catch (e) {
      navigate('/enroll-company/ubos')
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        console.log(res.data)

        setPeople(res.data.actors)
      }

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <Boxed>
        {people
          .filter((person) => person.isDirector)
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              initialEdit={true}
              isLoading={isDisabled}
            />
          ))}

        <AddMore>
          <h3>{trans.addANewDirector}</h3>
          <span>{trans.clickHereToAddANewDirector}</span>
          <button onClick={handleAddPerson}>
            <img src={IconPlus} alt="Add" />
            {trans.addNewDirector}
          </button>
        </AddMore>

        <ToRight>
          <ProceedButton onClick={handleProceed} disabled={isDisabled}>
            <span>{trans.proceed}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
})
