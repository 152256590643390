import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { StoreContext } from '../../../components/App'
import {
  AddNewUbosContainer,
  Boxed,
  Container,
  ProceedButton,
} from './enroll.styles'
import { AssignDirectorInput } from '../../../components/AssignDirectorInput/AssignDirectorInput'
import { Actor, blankActor } from './Directors'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { ToRight } from '../EnrollCompany.styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const Ubos = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { ubos: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)

  const [people, setPeople] = useState<Actor[]>([])
  const [newUboIds, setNewUboIds] = useState([])

  const addDirectorToUbosHandler = (ids) => {
    setNewUboIds(ids)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId in ids
          ? { ...person, isUbo: ids[person.actorId] }
          : person
      )
    )
  }

  const handleAddPerson = () => {
    console.log('add')
  }

  const handleDeletePerson = (id: number) => {
    console.log('delete')
  }

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        {
          edit: people
            .filter((person) => person.isUbo)
            .map((person) => {
              return {
                ...person,
                info: {
                  ...(person.info ? person.info : blankActor.info),
                  dateOfBirth:
                    person.info?.dateOfBirth ||
                    person.providerInfo?.dateOfBirth ||
                    0,
                },
              }
            }),
        },
        {
          withCredentials: true,
        }
      )

      navigate('/enroll-company/summary')

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        console.log(res.data)

        setPeople(res.data.actors)
      }

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <Boxed>
        {people
          .filter((person) => person.isUbo)
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              isLoading={isDisabled}
            />
          ))}

        <AssignDirectorInput
          directors={people.filter(
            (person) => person.isDirector && !person.isUbo
          )}
          onChange={addDirectorToUbosHandler}
        />

        <AddNewUbosContainer>
          <h5>Add a new UBO’s</h5>
          <p>Click here to add a new UBO. Provide all required data.</p>
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0007 4.45703C10.2998 4.45703 10.5423 4.69954 10.5423 4.9987V9.45703L15.0007 9.45703C15.2998 9.45703 15.5423 9.69954 15.5423 9.9987C15.5423 10.2979 15.2998 10.5404 15.0007 10.5404H10.5423V14.9987C10.5423 15.2979 10.2998 15.5404 10.0007 15.5404C9.7015 15.5404 9.45898 15.2979 9.45898 14.9987V10.5404L5.00065 10.5404C4.7015 10.5404 4.45898 10.2979 4.45898 9.9987C4.45898 9.69954 4.7015 9.45703 5.00065 9.45703L9.45898 9.45703V4.9987C9.45898 4.69954 9.7015 4.45703 10.0007 4.45703Z"
                fill="#0071F3"
              />
            </svg>
            Add new UBO
          </button>
        </AddNewUbosContainer>

        <ToRight>
          <ProceedButton onClick={handleProceed} disabled={isDisabled}>
            <span>{trans.proceed}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
})
