import styled from 'styled-components'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 503px;
  width: 100%;

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const FormSection = styled.div`
  h3 {
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin: 40px 0 0 0;
    padding: 0;
  }
`

interface FormSectionOptionalProps {
  visible: boolean
}

export const FormSectionOptional = styled.div<FormSectionOptionalProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

interface ProceedButton {
  margin?: string
}

export const ProceedButton = styled.button<ProceedButton>`
  border: none;
  outline: none;
  background-color: #0071f3;
  color: white;
  font-size: 14px;
  font-family: 'Inter';
  padding: 10px 44.5px;
  text-align: center;
  border-radius: 100px;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  @media (max-width: 980px) {
    width: 100%;
  }

  &:hover {
    background-color: #4298fb;
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(0, 113, 243, 0.3);
  }

  &:disabled {
    background-color: #acb0b5;
    cursor: auto;
  }
`

export const InputRadio = styled.input``

export const Boxed = styled.div``

export const AddMore = styled.div`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 503px;

  h3 {
    font-family: 'Inter';
    font-size: 18px;
    color: #2b253a;
    font-weight: 600;
    padding: 0;
    margin: 0 0 8px 0;
  }

  span {
    color: #565b60;
    font-size: 14px;
    margin-bottom: 24px;
  }

  button {
    display: flex;
    padding: 10px 24px;
    min-width: 120px;
    justify-content: center;
    align-items: center;
    border: 2px solid #cecbd6;
    background: #fff;
    border-radius: 100px;
    color: #0071f3;
    font-weight: 500;
    line-height: 20px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const UbosContainer = styled.div`
  width: 100%;
  max-width: 503px;

  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #e9e7ee;
  background: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);

  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  gap: 16px;

  h5 {
    margin: 0;
    color: #2b253a;
    /* Headline 5 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }

  p {
    margin: 0;
    color: #565b60;
    /* Paragraph 4 - Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const AddNewUbosContainer = styled(UbosContainer)`
  align-items: flex-start;
  margin: 40px 0;

  button {
    height: 40px;
    outline: none;
    border-radius: 100px;
    border: 2px solid #cecbd6;
    background: #fff;

    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 24px;

    color: #0071f3;
    text-align: center;

    /* Paragraph 4 - Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    cursor: pointer;
    transition: background 0.3s ease-in-out;
    &:hover {
      background: #f8f7fa;
    }
  }
`

export const PollingLoadingBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 250px;

  color: var(--Dark-grey, #34353a);
  text-align: center;

  /* Paragraph 5 - Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`

export const ErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 250px;
  gap: 20px;

  h1,
  main h1 {
    margin: 0;
    color: var(--Text-text-header-primary, #2b253a);
    font-family: Inter;
    font-size: var(--Fonts-Size-Headline-4, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Fonts-Line-height-Headline-4, 36px); /* 150% */
  }

  h2 {
    margin: 0;
    color: var(--Text-text-secondary, #565b60);
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-3, 24px); /* 150% */
    letter-spacing: -0.16px;
  }

  main {
    border-radius: 8px;
    border: 1px solid var(--Stroke-card, #e6ddf9);
    background: #fff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 500px;
  }
`

export const Circle = styled.div`
  width: 120px;
  height: 120px;
  border-radius: var(--Radius-round, 100px);
  background: var(--Background-bg-secondary, #f7f9fc);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`
