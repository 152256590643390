import styled from 'styled-components'
import { Cascader } from 'antd'

export const StyledCascader = styled(Cascader)`
  width: 100%;
  height: 100%;

  &:where(.css-dev-only-do-not-override-1c0na6j).ant-select-outlined:not(
      .ant-select-disabled
    ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 1px solid #c6c3c3 !important;
  }

  .ant-select-selector {
    border: 1px solid #c6c3c3 !important;
    border-radius: 4px;
  }
`
