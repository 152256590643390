import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../components/App'

import { Link } from 'react-router-dom'
import identity_verification_welcome_splash from '../../../assets/identity_verification_welcome_splash.png'
import {
  Button,
  Container,
  Header,
  Splash,
  SubHeader,
  ToRight,
} from '../EnrollCompany.styles'

export const IntroIdentity = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { introIdentity: trans },
  } = store.TranslationsState.translations

  return (
    <Container>
      <Header>{trans.title}</Header>
      <SubHeader>{trans.subtitle}</SubHeader>
      <Splash src={identity_verification_welcome_splash} alt="" />
      <ToRight>
        <Link to="/enroll-company/identity-verification">
          <Button type="button">{trans.continue}</Button>
        </Link>
      </ToRight>
    </Container>
  )
})
