import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StoreContext } from '../../../../components/App'
import { Container, InputWrap } from './summary.styles'
import { AnimatedBorder } from '../../../../components/AnimatedInput/AnimatedBorder'
import { Icon } from '../../../../components/Icons/Icon.styles'
import IconPencil from '../../../../assets/pencil.svg'
import IconCalendar from '../../../../assets/calendar.svg'
import IconPin from '../../../../assets/pin.svg'
import {
  CountrySelect,
  CountrySelectItem,
} from '../../../../components/CountrySelect/CountrySelect'
import axios from 'axios'
import { findCountry } from '../../../../components/CountrySelect/methods'
import { FormSection, ProceedButton } from '../enroll.styles'
import { TextInput } from '../../../../components/TextInput/TextInput'

interface PersonalDataProps {
  initialEdit: boolean
}

export const PersonalData: React.FC<PersonalDataProps> = ({ initialEdit }) => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    formLabels: transForm,
    enrollCompanyPage: { addressVerification: trans },
  } = store.TranslationsState.translations

  const [edit, setEdit] = useState(initialEdit)

  const [isDisabled, setIsDisabled] = useState(true)
  const [isProceedDisabled, setIsProceedDisabled] = useState(true)

  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState<CountrySelectItem>({
    value: '',
    label: transForm.selectCountry,
  })

  const [addressFetched, setAddressFetched] = useState(false)
  const [address2Fetched, setAddress2Fetched] = useState(false)
  const [cityFetched, setCityFetched] = useState(false)
  const [postalCodeFetched, setPostalCodeFetched] = useState(false)
  const [countryFetched, setCountryFetched] = useState(false)

  const getAddressData = async () => {
    setName('Jane Doe')
    setDateOfBirth('11.01.1967')
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actor/address`,
        { withCredentials: true }
      )

      if (res.data && res.data.identityProviderAddress) {
        const { identityProviderAddress } = res.data
        if (identityProviderAddress.line1) {
          setAddress(identityProviderAddress.line1)
          setAddressFetched(true)
        }
        if (identityProviderAddress.line2) {
          setAddress2(identityProviderAddress.line2)
          setAddress2Fetched(true)
        }
        if (identityProviderAddress.city) {
          setCity(identityProviderAddress.city)
          setCityFetched(true)
        }
        if (identityProviderAddress.postcode) {
          setPostalCode(identityProviderAddress.postcode)
          setPostalCodeFetched(true)
        }
        if (identityProviderAddress.country) {
          setCountry(findCountry(identityProviderAddress.country))
          setCountryFetched(true)
        }

        setTimeout(() => {
          setAddressFetched(false)
          setAddress2Fetched(false)
          setCityFetched(false)
          setPostalCodeFetched(false)
          setCountryFetched(false)
        }, 500)
      }

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
      setIsDisabled(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsDisabled(true)
      // sent data
      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actor/address`,
        {
          line1: address,
          line2: address2,
          city: city,
          postcode: postalCode,
          country: country.value,
        },
        { withCredentials: true }
      )
      window.location.href = `${process.env.WEB_API_URL}/merchant-enrolment/continue`
      setIsDisabled(false)
      setEdit(false)
    } catch (e) {
      setIsDisabled(false)
      setEdit(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getAddressData()
  }, [])

  useEffect(() => {
    if (
      address !== '' &&
      city !== '' &&
      postalCode !== '' &&
      country.value !== ''
    ) {
      setIsProceedDisabled(false)
    } else {
      setIsProceedDisabled(true)
    }
  }, [address, city, postalCode, country])

  return (
    <Container>
      <h3>{name}</h3>
      {!edit && (
        <button onClick={() => setEdit(!edit)} className="edit">
          <Icon size="24">
            <img src={IconPencil} alt="Edit" />
          </Icon>
        </button>
      )}

      {edit ? (
        <form onSubmit={(e) => e.preventDefault()}>
          <FormSection>
            <InputWrap>
              <label htmlFor="address">{trans.address}</label>
              <AnimatedBorder isLoading={isDisabled} isFilled={addressFetched}>
                <TextInput
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  name="address"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="address2">
                {trans.address} 2 ({trans.optional})
              </label>
              <AnimatedBorder isLoading={isDisabled} isFilled={address2Fetched}>
                <TextInput
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  name="address2"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="city">{trans.city}</label>
              <AnimatedBorder isLoading={isDisabled} isFilled={cityFetched}>
                <TextInput
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  name="city"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="postalCode">{trans.postalCode}</label>
              <AnimatedBorder
                isLoading={isDisabled}
                isFilled={postalCodeFetched}
              >
                <TextInput
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  name="postalCode"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
            <InputWrap>
              <label htmlFor="country">{trans.country}</label>
              <AnimatedBorder isLoading={isDisabled} isFilled={countryFetched}>
                <CountrySelect
                  value={country}
                  onChange={(val) => setCountry(val as CountrySelectItem)}
                  name="country"
                  disabled={isDisabled}
                />
              </AnimatedBorder>
            </InputWrap>
          </FormSection>
          <ProceedButton
            disabled={isProceedDisabled}
            onClick={() => handleSubmit()}
          >
            {trans.continue}
          </ProceedButton>
        </form>
      ) : (
        <>
          <div className="icon-text">
            <Icon size="24">
              <img src={IconCalendar} alt="Calendar" />
            </Icon>
            {dateOfBirth}
          </div>

          <div className="icon-text">
            <Icon size="24">
              <img src={IconPin} alt="Pin" />
            </Icon>
            {address} {address2}, {postalCode} {city}, {country.label}
          </div>
        </>
      )}
    </Container>
  )
}
