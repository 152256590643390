import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
	100% {
		transform: rotate(1turn);
	}
`

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Wrapper = styled.div<{
  isVisible?: boolean
  isFilled?: boolean
  width?: number
  height?: number
}>`
  /* margin: -2px 0 0 0; */
  position: relative;
  /* width: fit-content;
  height: fit-content; */
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  z-index: 0;
  border-radius: 4px;

  overflow: ${({ isVisible }) => (isVisible ? 'hidden' : 'visible')};

  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  min-width: 300px;
  @media (max-width: 980px) {
    width: 100%;
    min-width: 100%;
  }

  input,
  .country-select__control,
  .simple-select__control {
    width: 100%;
    height: 100%;
  }

  ${({ isVisible, width, height }) =>
    isVisible &&
    css`
      input {
        border: 1px solid transparent !important;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -2;
        left: ${width ? `-${width / 2}px` : '-50%'};
        /* top: ${width ? `-${width - height / 4}px` : '-50%'}; */
        width: 200%;
        aspect-ratio: 1 / 1;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#00000000, #00000000),
          linear-gradient(#00000000, #00000000),
          linear-gradient(#00000000, #00000000),
          linear-gradient(#377af5, #377af5);
        animation: ${rotate} 4s linear infinite;
      }

      /* &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 6px;
        top: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background: white;
        border-radius: 5px;
      } */
    `}

  ${({ isVisible, isFilled }) =>
    isVisible &&
    isFilled &&
    css`
      background-color: #377af5;
      background-image: none;
      filter: drop-shadow(0 0 5px rgba(55, 122, 245, 0.5));
    `}

    transition: background-color 0.3s, filter 0.3s;
`

export const InputPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 4px;
`
